<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <router-link to="/" class="text-dark">
        <i class="mdi mdi-home-variant h2"></i>
      </router-link>
    </div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="text-center">
              <router-link to="/" class="mb-5 d-block auth-logo">
                <img
                  src="@/assets/images/logo-groupe-emc.svg"
                  alt
                  height="150"
                  class="logo logo-dark m-auto"
                />
                <img
                  src="@/assets/images/logo-groupe-emc.svg"
                  alt
                  height="150"
                  class="logo logo-light m-auto"
                />
              </router-link>
              <div class="card">
                <div class="card-body p-4">
                  <div class="text-center mt-2">
                    <h5 class="text-primary">Réinitialiser le mot de passe</h5>
                  </div>
                  <div class="p-2 mt-4">
                    <div
                      class="alert alert-success text-center mb-4"
                      role="alert"
                    >
                      Entrez votre email et nous vous enverrons un lien de réinitialisation à durée limitée.
                    </div>
                    <form @submit.prevent="tryToReset">
                      <div class="mb-3">
                        <label for="useremail">Email</label>
                        <input
                          type="email"
                          v-model="email"
                          class="form-control"
                          id="useremail"
                          placeholder="Entrer un email"
                          :class="{
                            'is-invalid': submitted && $v.email.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.email.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.email.required"
                            >Veuillez entrer votre email.</span
                          >
                          <span v-if="!$v.email.email"
                            >Veuillez entrer un email valide.</span
                          >
                        </div>
                      </div>
                      <div class="row mb-0">
                        <div class="col-12 text-end">
                          <button class="btn btn-primary w-sm" type="submit">
                            Recevoir un lien de réinitialisation
                          </button>
                        </div>
                      </div>
                      <div class="mt-4 text-center">
                        <p class="mb-0">
                          Vous vous en souvenez ?
                          <router-link
                            to="/login"
                            class="fw-medium text-primary"
                            >Se connecter</router-link
                          >
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->

              <div class="mt-5 text-center">
                <p>
                  © {{ new Date().getFullYear() }} Groupe EMC
                </p>
              </div>
            </div>
            <!-- end col -->
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<script>
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import { required, email } from "vuelidate/lib/validators";
import api from '../../../api/api';
import Utilities from "../../../services/utilities";

/**
 * Forgot Password component
 */
export default {
  page: {
    title: "Mot de passe oublié",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      email: "",
      submitted: false,
      error: null,
      title: "Mot de passe oublié",
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    tryToReset() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        if (!this.email || this.email == '') {
            this.error = true;
            return;
        }

        api
            .post('/account/forgot-password', {
                'email': this.email
            })
            .then(
                (response) => {
                    if (response && response.status == 200) {
                        this.submitted = true;
                        this.error = false;
                        Swal.fire({
                            title: "Succès",
                            text: response.data,
                            icon: "success",
                            confirmButtonColor: "#2e58a6",
                            confirmButtonText: "Retour",
                        }).then(
                            (result) => {
                              if (result.isConfirmed) {
                                this.$router.push('/login');
                              }
                            }
                        );
                    }
                },
                (error) => {
                    Utilities.alertmsg(error.response.data.detail);
                    this.submitted = true;
                }
            )
      }
    },
  },
};
</script>

<style lang="scss" module></style>
